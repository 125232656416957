<template>
  <h1>OurCustomers</h1>
  <p>
    You can contact our amazing customer support via <a href="mailto:support@ourcustomers.cc">email</a> or using the contact form below
  </p>
  <Contact/>
</template>

<script>
import Contact from './components/Contact.vue'

import './css/main.css'

export default {
  name: 'App',
  components: {
    Contact
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
