<template>
    <section>
        <div id="contact-form" class="contact-form">
            <h1 class="contact-form_title">Contact Form</h1>
            <div class="separator"></div>

            <div v-if="isSending" class="loading">Sending...</div>
            <div v-if="captchaFail" class="loading">Could not verify captcha. Try again please.</div>

            <form class="form" @submit="onSubmit">
                <input required name="name" v-model='contact.name' placeholder="Name" type="text" autocomplete="off">
                <input required name="email" v-model="contact.email" placeholder="E-mail" type="email" autocomplete="off">
                <textarea name="message" v-model="contact.message" rows="4" placeholder="Message"></textarea>

<!-- <button @click="recaptcha">Execute recaptcha</button> -->
                    
                <button class="button">Send</button>
            </form>
        </div>
    </section>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
	'el': '#contact-form',


	data() {
        return {
            contact: {
                name: '',
                email: '',
                message: '',
            },
            show: 1,
            verified: false,
            isSending: false,
            captchaFail: false
        }
	},

	methods: {

		/**
		 * Clear the form
		 */	
		clearForm() {
			for (let field in this.contact) {
				this.contact[field] = ''
			}
		},

		/**
		 * Handler for submit
		 */	
		onSubmit(evt) {

            this.recaptcha();

			evt.preventDefault();

			this.isSending = true;

			setTimeout(() => {
                if( this.verified ) {
                    // Build for data
                    let form = new FormData();
                    for (let field in this.contact) {
                        form.append(field, this.contact[field]);
                    }

                    try {
                        form.append('from', this.contact.email);
                        emailjs.sendForm('service_m42l9vs', 'template_wzttfqi', evt.target, 'user_rcw0ZA2RmF5mgwVi0uiqt', form)
                        console.log('it works!!!')
                        this.clearForm();
                        this.isSending = false;
                    } catch (error) {
                        console.log({error})
                    }
                } else {
                    this.captchaFail = true;
                }

                

			}, 1000);
		},

        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')

            if( token ) {
                this.verified = true;
            }
            console.log(token)
        }
	}
}
</script>
