import { createApp } from 'vue'
import App from './App.vue'
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

const component = {
    setup() {
      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
  
      const recaptcha = async () => {
        // (optional) Wait until recaptcha has been loaded.
        await recaptchaLoaded()
  
        // Execute reCAPTCHA with action "login".
        const token = await executeRecaptcha('login')
        console.log(token)
        // Do stuff with the received token.
      }
  
      return {
        recaptcha
      }
    },
    template: ''
  }

createApp(App)
    
    .component('Captcha', component)
    .use(VueReCaptcha, { siteKey: '6LfT84waAAAAAJTg1RI9W9LvRBJ0sf4-DyO3BDdj' })
    .mount('#app')
